import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss']
})
export class ProductionComponent implements OnInit {

  productionScripts = [
    {name: 'Take RDS Prod Snapshot', link: 'https://helper-scripts.bizzycar.com/prod-rds-snapshot'},
    {name: 'Download API Server production.log file', link: 'https://helper-scripts.bizzycar.com/get-prod-api-logs'},
    {name: 'Download API Server sidekiq.log file', link: 'https://helper-scripts.bizzycar.com/get-prod-api-sidekiq-logs'},
    {name: 'Generate Lambda Report', link: 'https://helper-scripts.bizzycar.com/generate-lambda-report'},
    {name: 'Get Deploy Logs', link: 'https://helper-scripts.bizzycar.com/get-production-deploy-logs'},
    {name: 'Generate DMS report', link: 'https://helper-scripts.bizzycar.com/generate-dms-report'},
    {name: 'Trigger CDK Poll Pipeline', link: 'https://helper-scripts.bizzycar.com/trigger-cdk-poll-pipeline'},
  ]

  constructor() { }

  ngOnInit() {
  }

}
