import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SandboxComponent } from './sandbox/sandbox.component';
import { StagingComponent } from './staging/staging.component';
import { ProductionComponent } from './production/production.component';
import { ConfigComponent } from './config/config.component';

const routes: Routes = [
  { path: 'staging', component: StagingComponent },
  { path: 'sandbox', component: SandboxComponent },
  { path: 'production', component: ProductionComponent },
  { path: 'config', component: ConfigComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
