import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  configScripts = [
    {name: 'Display SSH commands', link: 'https://helper-scripts.bizzycar.com/list-ssh'},
    {name: 'Get slack config', link: 'https://helper-scripts.bizzycar.com/which-slack-endpoint'},
    {name: 'Jenkins', link: 'https://jenkins.bizzycar.com/'},
    {name: 'SonarQube', link: 'http://jenkins.bizzycar.com:9000/projects'},
    {name: 'Datadog', link: 'https://app.datadoghq.com/logs?query=&cols=host%2Cservice&index=%2A&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1668551054760&to_ts=1668551954760&live=true'},
    {name: 'Run Cypress on Branch:', link: 'https://helper-scripts.bizzycar.com/run-cypress'},
    {name: 'List CodeBuild Projects:', link: 'https://helper-scripts.bizzycar.com/list-build-projects'},
    {name: 'Get CodeBuild log for project:', link: 'https://helper-scripts.bizzycar.com/get-build-logs'},
    {name: 'Get all healthchecks:', link: 'https://helper-scripts.bizzycar.com/get-all-healthchecks'},
  ]

  constructor() { }

  ngOnInit() {
  }

  updateCypressBranchName(name:string) {
    const url = this.configScripts[5].link + `?name=${name}`;
    window.open(url, '_blank');
  }

  addCodeBuildProjectName(name:string) {
    const url = this.configScripts[7].link + `?name=${name}`;
    window.open(url, '_blank');
  }
}
