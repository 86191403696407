import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnInit {
  sandboxScripts = [
    {name: 'Reseed', link: 'https://helper-scripts.bizzycar.com/sandbox'},
    {name: 'Re deploy API Server', link: 'https://helper-scripts.bizzycar.com/deploy-sandbox'},
    {name: 'Reload Seed from S3', link: 'https://helper-scripts.bizzycar.com/reload-sandbox-seed'},
    {name: 'Update Seed on S3', link: 'https://helper-scripts.bizzycar.com/update-sandbox-seed-s3'},
    {name: 'Get API Server branch name', link: 'https://helper-scripts.bizzycar.com/get-sandbox-api-branch-name'},
    {name: 'Update API branch name', link: 'https://helper-scripts.bizzycar.com/update-sandbox-api-branch'},
    {name: 'Get WEB branch name', link: 'https://helper-scripts.bizzycar.com/get-sandbox-web-branch-name'},
    {name: 'Update WEB branch name', link: 'https://helper-scripts.bizzycar.com/update-sandbox-web-branch-name'},
    {name: 'Re deploy WEB', link: 'https://helper-scripts.bizzycar.com/deploy-sandbox-web'},
    {name: 'Pause RDS Instance', link: 'https://helper-scripts.bizzycar.com/pause-rds-sandbox'},
    {name: 'Resume RDS Instance', link: 'https://helper-scripts.bizzycar.com/resume-rds-sandbox'},
    {name: 'Get RDS Status', link: 'https://helper-scripts.bizzycar.com/rds_status_sandbox'},
    {name: 'Seed Customer Tasks', link: 'https://helper-scripts.bizzycar.com/seed-sandbox-customer-tasks'},
    {name: 'Seed Business Tasks', link: 'https://helper-scripts.bizzycar.com/seed-sandbox-business-tasks'},
    {name: 'Turn demo wall on', link: 'https://helper-scripts.bizzycar.com/set-sandbox-demo-page-true'},
    {name: 'Turn demo wall off', link: 'https://helper-scripts.bizzycar.com/set-sandbox-demo-page-false'},
    {name: 'Get Deploy Logs', link: 'https://helper-scripts.bizzycar.com/get-sandbox-deploy-logs'},
    {name: 'Run Command on Sandbox', link: 'https://helper-scripts.bizzycar.com/run-command-on-sandbox'},
    {name: 'View Pods', link: 'https://helper-scripts.bizzycar.com/view-sandbox-pods'},
    {name: 'Change Language Filter to US', link: 'https://helper-scripts.bizzycar.com/change-language-filter-us'},
    {name: 'Change Language Filter to CA', link: 'https://helper-scripts.bizzycar.com/change-language-filter-ca'},
  ]

  constructor() { }

  ngOnInit() {
  }

  updateAPIBranchName(name:string) {
    const url = this.sandboxScripts[5].link + `?name=${name}`;
    window.open(url, '_blank');
  }

  updateWEBBranchName(name:string) {
    const url = this.sandboxScripts[7].link + `?name=${name}`;
    window.open(url, '_blank');
  }

  runCommand(name:string) {
    const url = this.sandboxScripts[17].link + `?name=${name}`;
    window.open(url, '_blank');
  }
}
