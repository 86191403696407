import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staging',
  templateUrl: './staging.component.html',
  styleUrls: ['./staging.component.scss']
})
export class StagingComponent implements OnInit {

  stagingScripts = [
    {name: 'Reseed', link: 'https://helper-scripts.bizzycar.com/staging'},
    {name: 'Re deploy API Server', link: 'https://helper-scripts.bizzycar.com/deploy-staging'},
    {name: 'Reload Seed from S3', link: 'https://helper-scripts.bizzycar.com/reload-staging-seed'},
    {name: 'Update Seed on S3', link: 'https://helper-scripts.bizzycar.com/update-staging-seed-s3'},
    {name: 'Pause RDS Instance', link: 'https://helper-scripts.bizzycar.com/pause-rds-staging'},
    {name: 'Resume RDS Instance', link: 'https://helper-scripts.bizzycar.com/resume-rds-staging'},
    {name: 'Get RDS Status', link: 'https://helper-scripts.bizzycar.com/rds_status_staging'},
    {name: 'Seed Customer Tasks', link: 'https://helper-scripts.bizzycar.com/seed-staging-customer-tasks'},
    {name: 'Seed Business Tasks', link: 'https://helper-scripts.bizzycar.com/seed-staging-business-tasks'},
    {name: 'Turn demo wall on', link: 'https://helper-scripts.bizzycar.com/set-staging-demo-page-true'},
    {name: 'Turn demo wall off', link: 'https://helper-scripts.bizzycar.com/set-staging-demo-page-false'},
    {name: 'Get Deploy Logs', link: 'https://helper-scripts.bizzycar.com/get-staging-deploy-logs'},
  ]

  constructor() { }

  ngOnInit() {
  }

}
